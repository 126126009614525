import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const InputField = ({
  id,
  value,
  onChange = () => {},
  onBlur = () => {},
  color = 'primary',
  variant = 'standard',
  fullWidth = true,
  multiline = false,
  readOnly = false,
  label = '',
  placeholder = '',
  helperText = '',
  errorText = 'Valore non valido.',
  errorFunc = () => false,
  size = 'medium',
  type = 'text',
}) => {
  const error = errorFunc(value)

  return (
    <TextField
      id={id}
      value={value || ''}
      color={color}
      variant={variant}
      error={!!error}
      fullWidth={!!fullWidth}
      multiline={!!multiline}
      label={label}
      placeholder={placeholder}
      onChange={event => onChange(event.target.value)}
      onBlur={event => onBlur(event.target.value)}
      helperText={!!error ? errorText : helperText}
      size={size}
      type={type}
      InputProps={{ readOnly: !!readOnly }}
    />
  )
}

InputField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  errorText: PropTypes.string,
  errorFunc: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.oneOf(['email', 'password', 'text']),
}

export default InputField
